@charset "utf-8"; /* [#CONVERT-2-UTF8] */

/* include scss */
@import "_foundation.scss";
@import "_reset.scss";
@import "_mixins.scss";
@import "hamburgers/hamburgers.scss";

@import "../fonts/fonts.scss";
@import "../../slick/slick.scss";
@import "../../slick/slick-theme.scss"; 

@import "select-css.scss";
@import "select-css-compat.scss";



@import "jquery-ui.scss";
@import "jquery-ui.structure.scss";
@import "jquery-ui.theme.scss";
@import "jquery-ui-slider-pips.scss";

// responsive
$width-medium: 40.063em;
$width-large: 64.063em;
$width-xlarge: 90.050em;

$font-regular: 'Roboto Condensed', Helvetica, Geneva, sans-serif;
$font-headings: 'Roboto Condensed', Helvetica, Geneva, sans-serif;
$text-color: #333;
$link-color: #1e1e1c;
$link-color-hover: #AEA39D;

$nav-color: #1e1e1c;
$nav-color-hover: #AEA39D;
$nav-color-active: #AEA39D;
$nav-mobile-color: #fff;
$nav-mobile-color-hover: #ccc;
$nav-mobile-color-active: #273546;

$bg-color: #fff;
$content-bg-color: #fff;
$header-bg-color: #fff;

/**
 * change CI colors here
 */
//Rostbraun: B66B4C, Blau: 324B5C
$bk-intro-line-color: #AEA39D; // 324B5C

$bk-search-bg-color: #AEA39D;
$bk-search-color: #fff;
$bk-search-field-color: #AAAAAA;
$bk-search-field-bg-color: #EEEEEE;
$bk-search-field-disabled-color: #AAAAAA;
$bk-search-line-color: #C6C6C6;
$bk-search-line2-color: #C6C6C6;
$bk-search-slider-bg-color: #cfdbe5;
$bk-search-slider-active-color: #fff;
$bk-search-slider-inactive-color: #ccc;

$bk-search-note-bg-color: #1e1e1c;
$bk-search-note-color: #fff;
$bk-search-note-icon-color: #fff;
$bk-search-note-bottom: 3vh; /* margin from bottom viewport */

$bk-option-hover-color: #bbcad6;
$bk-option-note-costs-color: #b17045;
$bk-first-links-color: #fff;
$bk-first-links-color-hover: #ddd;
$bk-form-success-color: #cfc;
$bk-form-error-color: #f66;
$bk-form-error-color-light: #fd9;

$bk-results-header-bg-color: #AEA39D;
$bk-results-header-color: #fff;
$bk-results-line-color: #1e1e1c;

$bk-results-bg-color: #EDF0F2;
$bk-results-active-bg-color: #fff;

$bk-radio-line-color: #999;
$bk-radio-line-hover-color: #1e1e1c;
$bk-radio-line-checked-color: #1e1e1c;
$bk-radio-inner-checked-color: #1e1e1c;


/* width, paddings, margins */
$content-width: 1200px;
$content-padding-left-large: 40px;
$content-padding-left-small: 20px;
$content-padding-right-large: 40px;
$content-padding-right-small: 20px;
$content-padding-top-large: 224px;
$content-padding-top-small: 140px;
$content-padding-bottom-large: 20px;
$content-padding-bottom-small: 20px;


@import "rc-slider.scss";


/* @group Global */
html, body {
	padding: 0;
	margin: 0;
	font-size: 62.5%; /*font-size: 62.5%;*/
	line-height: 1.5em;
}

body {
	color: $text-color;
	text-align: center;
	font-family: $font-regular;
	font-style: normal;
	font-weight: 400;
	background-color: $bg-color;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-variant-ligatures: no-common-ligatures;
	font-variant-ligatures: no-common-ligatures;
	-webkit-text-size-adjust: 100%;
	-moz-font-feature-settings: "lnum";
	-webkit-font-feature-settings: "lnum";
	font-feature-settings: "lnum";
	font-feature-settings: "lnum";
}


/* replacing image attribute border="0" in stylesheet is recommended */
img {
	border: 0px;
	display: block; /* xhtml strict: no baseline for image tags (small margin bottom) */
	-ms-interpolation-mode: bicubic;
}
strong, b {
	font-style: normal;
	font-weight: 600;
}
em {
	font-style: italic;
}
p {
	margin-top: 0;
	padding: 0;
	margin: 0;
	padding-bottom: 1em;
	padding-right: 0.25em;
}
h1, h2, h3, h4 {
	font-family: $font-headings;
	font-style: normal;
	font-weight: 300;
	font-size: 2.4rem;
	line-height: 1.5em;
	padding-bottom: 0;
	text-align: left;
	
}
h1 {
	/*font-size: 6rem;
	line-height: 1em;*/
	text-transform: none;
	padding-bottom: 0.25em;
}
/*h2 {
	text-transform: uppercase;
}*/
h3, h4 {
	font-size: 2rem;
	line-height: 1.5em;
	margin-top: 0.5em;
}
a:link, a:visited {
	color: $link-color;
	text-decoration: none;
}
.no-touchevents a:hover { /* .notouch */
	color: $link-color-hover;
}
small {
	font-size: 1.6rem;
	line-height: 1.3em;
}
sup {
	position: relative;
	font-size: 1.1rem;
	line-height: 0;
	vertical-align: top;
	position: relative;
	top: 0.75em;
}
/* self-clear floats */

.group:after {
	content: " ";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
}
* html .group {
	/* IE6 */
	height: 1%;
}
*:first-child+html .group {
	/* IE7 */
	min-height: 1px;
}
.browserupgrade {
	position: relative;
	z-index: 9999;
	font-family: Arial, Helvetica, sans-serif !important;
	padding: 12px;
	font-size: 14px;
	text-align: center;
	color: #000 !important;
	background-color: #ccc;
}
/* @end */



/* @group Main */
#root {
	position: relative;
	text-align: center;
	margin: auto;
	font-size: 1.8rem;
	line-height: 1.5em;
}

header {
	position: fixed;
	z-index: 9999;
	background-color: $header-bg-color;
	//padding: 12px;
	#logo {
		display: block;
		/*width: 320px;*/
		margin: auto;
		img {
			display: block;
			max-width: 100%;
		}
	}
	.hamburger {
		position: absolute;
		/*top: 9px;
		right: 12px;*/
	}
	.columns {
		padding-right: 0 !important;
	}
}

main { //#content
	display: block;
	position: relative;
	padding-left: 0;
	padding-right: 0;
	background-color: $content-bg-color;
	/*padding-top: $content-padding-top-large;
	padding-bottom: $content-padding-bottom-large;*/
	text-align: left;
	> section {
		padding-top: 2em;
		padding-bottom: 2em;
		> .row, > form > .row {
			/*padding-left: $content-padding-left-large;
			padding-right: $content-padding-right-large;*/
			max-width: $content-width;
			margin: auto;
			.large-12 {
				padding-right: 0 !important;
			}
		}
	}
}

footer {
	max-width: $content-width;
	margin: auto;
	padding-top: 24px;
	padding-bottom: 72px;
	font-size: 0.7em;
	line-height: 1.3em;
	text-align: center;
}
/* @end */



/* @group Intro */
.bk-intro {
	> .row {
		> .columns {
			position: relative;
			//padding-right: $content-padding-right-large !important; 
			&:first-of-type {
				h1 {
					//text-align: right;
				}
			}
		}
	}
}
/* @end */


/* @group Breadcrumb */
#bk-breadcrumb {
	position: absolute; 
	width: 100%;
	> div {
		padding: 0; 
		margin: auto; 
		max-width: 950px;
		/*padding-left: $content-padding-left-large * 3;
		padding-right: $content-padding-right-large * 3;*/
	}
	ul {
		position: relative;
		display: flex;
		margin-top: -18px;
		justify-content: space-between;
		li {
			display: block;
			position: relative;
			z-index: 99;
			text-align: center;
			font-weight: 700;
			line-height: 1.3em;
			color: #fff;
			background-color: $bk-search-bg-color;
			border: 1px solid #fff;
			color: #fff;
			width: 36px;
			height: 36px;
			padding: 6px;
			@include rounded(36px);
		}
		li.active {
			border: 1px solid $bk-search-bg-color;
			background-color: #fff;
			color: $text-color;
			padding: 0;
			a {
				display: block;
				padding: 6px;
				color: $text-color;
			}
		}
	}
}
/* @end */



/* @group Search Form */
.bk-search, .bk-options, .bk-first, .modal-content, .bk-configurations-filters {
	position: relative;
	background-color: $bk-search-bg-color;
	max-width: $content-width;
	margin: auto;
	color: $bk-search-color;
	form {
		input, select, textarea {
			font-size: 2.2rem;
			line-height: 1.3em;
			font-family: $font-regular;
			font-style: normal;
			font-weight: 400;
			border: 2px solid #DDDDDD;
			color: $bk-search-field-color;
			background-color: $bk-search-field-bg-color;
		}
		input::placeholder {
			color: $bk-search-field-color;
		}
		input:disabled {
			color: $bk-search-field-disabled-color;
		}
		input[type=text], input[type=number], input[type=password], input[type=email], textarea {
			width: 100%;
			padding: 8px;
			padding-left: 10px;
		}
		label {
			position: relative;
			font-size: 2.2rem;
			line-height: 1.2em;
			display: inline-block;
			background-color: transparent;
			font-family: $font-regular;
			font-weight: 400;
		}
		label:hover {
			cursor: pointer;
		}
		.columns {
			
			float: left !important;
		}
		.disabled {
			@include opacity(0.5);
		}
		ul {
			li {
				/*width: 50%;
				float: left;*/
				padding-bottom: 0;
				ul {
					li {
						float: left;
					}
				}
			}
		}
		/* forms on single pages (login...) */
		ol {
			padding-top: 1em;
			li {
				padding-bottom: 1em;
				&.column:last-of-type {
					padding-right: 0 !important;
				}
			}
		}
	}
		.bk-search-group-basic {
			padding-top: 1em;
			.columns {
				position: relative;
				/*padding-left: 90px;
				padding-right: 90px;*/
				padding-bottom: 2em;
				> div {
					/*width: 260px;*/
					max-width: 100%;
					/*float: left;*/
					img {
						display: block;
						max-width: 181px;
						margin: auto;
					}
					label {
						display: block;
						span {
							display: none;
						}
					}
				}
			}
			.columns:first-of-type {
				> div {
					/*float: right;*/
				}
			}
			.columns:first-of-type:after {
				content: '';
				position: absolute;
				right: -1px;
				top: 0;
				bottom: 2em;
				display: block;
				height: auto;
				border-right: 2px solid $bk-search-line-color;
			}
			
		}
		.bk-search-group-options, .bk-options-group, .bk-options-next {
			> .columns {
				padding-top: 2em;
				padding-bottom: 1em;
				border-top: 1px solid $bk-search-line2-color;
				ul {
					padding-left: 0;
					margin-left: 0;
					li {
						padding-right: 0.5em !important;
						padding-bottom: 1em;
						label {
							text-align: left;
						}
					}
				}
				img {
					display: block;
					//width: 180px;
					max-width: 100%;
				}
				h2 {
					font-size: 3.6rem;
				}
			}
			&.bk-search-group-options {
				> .columns {
					h2 {
						font-size: 2.4rem;
					}
				}
			}
			> .columns:first-of-type {
				border-top: none;
			}
		}
		.bk-search-group-option-layout, .bk-options-icons, .bk-options-thumbs, .bk-options-radios {
			ul {
				li {
					label {
						padding-left: 0;
						white-space: normal;
						> span {
							display: block;
							padding-left: 32px;
							padding-right: 24px;
							position: relative !important;
							small {
								position: relative;
								display: inline-block;
								text-transform: none;
								font-weight: 400;
								padding-left: 4px;
								/*width: 75vw;*/
							}
							.bk-btn-info {
								top: 0;
								right: -12px;
							}
						}
						.bk-radio-image {
							display: block;
							max-width: 100%;
							margin-bottom: 0.5em;
						}
						.bk-radio-image + input + span { /* horizontal radios */
							small {
								padding-left: 0;
							}
						}
					}
				}
					
			}
		}
		.bk-search-group-option-layout, .bk-options-types {
			ul {
				li {
					label {
						> span {
							display: inline-block; /* for info button */
						}
					}
				}
			}
		}
		.bk-options-types {
			ul {
				li {
					/*width: 33% !important;*/
					label {
						display: block;
						width: 180px !important;
						margin: auto;
					}
				}
			}
		}
		.bk-options-icons, .bk-options-thumbs {
			ul {
				/*margin-right: -1 * $content-padding-right-large;*/
				li {
					label {
						width: 100%;
						padding-left: 0;
						> span {
							position: relative;
							z-index: 1;
							small {
								padding-top: 2px;
							}
						}
						> span:before {
							display: block;
						  	position: absolute;
						  	z-index: -1;
						  	top: -20px;
							content: '';
							border-radius: 100%;
							background-color: #fff;
						  	height: 52px;
						  	width: 52px;
							left: -14px;
							margin: auto;
						}
					}
					/*width: 33%;*/
					padding-bottom: 2em;
					img {
						display: block;
						width: 100% !important;
						max-width: 600px !important;
					}
				}
			}
		}
		.bk-options-icons {
			ul {
				li {
					label {
						text-align: center !important;
					}
				}
			}
		}
		.bk-options-thumbs {
			h2 {
				a {
					display: block;
					&:after {
						position: absolute;
						display: block;
						z-index: 999;
						right: 0;
						top: 0.5em;
						content: '';
						width: 0;
						height: 0;
						border-color: transparent;
						border-right-color: $link-color;
						border-style: solid;
						border-width: 10px 10px 10px 0;
							
					}
					&.active:after {
						right: 0;
						top: 0.6em;
						border-color: transparent;
						border-width: 10px 10px 0 10px;
						border-top-color: $link-color;
					}
				}
			}
		}
		.bk-options-next {
			.columns {
				border-top: none !important;
			}
			.columns:last-of-type {
				text-align: right;
				padding-right: 0 !important;
			}
			
		}
		.bk-search-group-option-ratio {
			border-top: none !important;
			padding-top: 0 !important;
			margin-top: -0.5em;
			> .columns:first-of-type {
				padding-left: 2em;
			}
			ul {
				li {
					padding-left: 1em;
					padding-bottom: 0.5em;
					&:first-of-type {
						padding-left: 0.5em;
						padding-bottom: 0;
					}
				}
			}
			.bk-btn-info {
				top: 8px;
				right: -36px;
			}
			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: -6px;
				width: 24px;
				height: 24px;
				display: block;
				border: 1px solid #fff;
				border-top: 0;
				border-right: 0;
			}
		}
		.bk-search-group-option-rooms {
			label {
				margin-bottom: 0.75em;
			}
			> .columns {
				> ul {
					> li {
						padding-bottom: 0 !important;
						> ul {
							> li {
								label {
									> span {
										display: inline-block;
										padding-left: 30px;
										position: relative;
									}
								}
								
							}
						}
					}
				}
			}
		}
		.bk-options-radios {
			padding-bottom: 2em !important;
			.columns:last-of-type {
				padding-right: 0 !important;
				/*padding-right: $content-padding-right-large * 2;*/
			}
			ul {
				
				li {
					label {
						width: 100%;
						padding-left: 0;
					}
					float: none;
					padding-top: 4px;
					padding-bottom: 4px;
				}
			}
			ul ~ label {
				padding-top: 1em;
			}
		}
		.bk-options-summary {
			.columns:last-of-type {
				padding-right: 0 !important;
			}
			img {
				width: 100% !important;
			}
			.bk-layout-images {
				padding: 0;
			}
		}
	
	&.bk-first {
		form {
			margin: auto;
			max-width: 560px;
			padding-bottom: 1em;
			&.form-login {
				max-width: 480px;
			}
		}
	}
}
/* @end */

/* @group Modal */
.modal-close {
	position: absolute;
	right: 20px;
	top: 20px;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	background-color: #efefef;
	border: none;
	border-radius: 100%;
	cursor: pointer;
	display: inline-block;
	flex-grow: 0;
	flex-shrink: 0;
	font-size: 0;
	height: 40px;
	max-height: 20px;
	max-width: 20px;
	min-height: 20px;
	min-width: 20px;
	outline: none;
	vertical-align: top;
	width: 40px;
}
.modal-close::before, .modal-close::after {
	background-color: $text-color;
	content: "";
	display: block;
	left: 50%;
	position: absolute;
	top: 50%;
	-webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
	transform: translateX(-50%) translateY(-50%) rotate(45deg);
	-webkit-transform-origin: center center;
	transform-origin: center center;
}
.modal-close::before {
	height: 2px;
	width: 50%;
}
.modal-close::after {
	height: 50%;
	width: 2px;
}
.modal-close:hover, .modal-close:focus {
	background-color: rgba(204, 204, 204, 0.5);
}
.modal-close:active {
	background-color: rgba(204, 204, 204, 0.7);
}
.is-small.modal-close {
	height: 16px;
	max-height: 16px;
	max-width: 16px;
	min-height: 16px;
	min-width: 16px;
	width: 16px;
}
.is-medium.modal-close {
	height: 24px;
	max-height: 24px;
	max-width: 24px;
	min-height: 24px;
	min-width: 24px;
	width: 24px;
}
.is-large.modal-close {
	height: 32px;
	max-height: 32px;
	max-width: 32px;
	min-height: 32px;
	min-width: 32px;
	width: 32px;
}
.is-overlay, .modal, .modal-background {
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}
.modal {
	align-items: center;
	display: none;
	justify-content: center;
	overflow: hidden;
	position: fixed;
	z-index: 9999;
	width: 100%;
}
.modal:after {
	content:'';
	min-height:inherit;
	font-size:0;
}
.modal.is-active {
	display: block;
}
.flexbox .modal.is-active {
	display: flex;
}
.modal-background {
	background-color: rgba(255,255,255,0.7);
}
.modal-content-wrapper {
	display: block;
}
.modal-content {
	position: relative;
	margin: 0 20px;
	//max-height: calc(100vh - 160px);
	overflow: auto;
	position: relative;
	width: 100%;
	padding: 36px;
	text-align: center;
	background-color: $bk-search-color;
	color: $text-color;
	h2 {
		padding-bottom: 0.5em;
		text-align: center;
	}
	label {
		text-align: left;
		display: block !important;
	}
}
@media screen and (min-width: 769px), print {
	.modal-content {
		margin: 0 auto;
		//max-height: calc(100vh - 40px);
		width: 640px;
	}
}
/* @end */








.navoptions {
	text-align: center;
	padding-top: 1em;
	li {
		a {
			color: $bk-first-links-color;
			&:hover {
				color: $bk-first-links-color-hover;
			}
		}
	}
}


/* @group Slides */
.slides img {
	display: block;
	margin: auto;
}
.slides {
	.slick-prev, .slick-next {
		&:before {
			color: $text-color !important;
		}
	}
	.slick-dots {
		padding: 0 !important;
	    li {
			width: auto !important;
			padding: 0 !important;
	        button {
	            &:before {
	                font-size: 8px;
					color: $text-color !important;
				}
			}
		}
	}
}
/* for dark backgrounds add class inverted */
.slides.inverted {
	.slick-prev, .slick-next {
		&:before {
			color: $bk-first-links-color !important;
		}
	}
	.slick-dots {
	    li {
	        button {
	            &:before {
					color: $bk-first-links-color !important;
				}
			}
		}
	}
} 
/*.slides {
	padding-top: 0;
	padding-bottom: 0;
	margin-right: 0 !important;
}
.slides .slick-slide {
	padding-right: 0 !important;
	padding-bottom: 0 !important;
	background-color: #fcfcfc;
	margin-bottom: 20px;
}

.slides .slick-prev, .slides .slick-next {
	bottom: 70px !important;
    height: auto !important;
    min-height: 90%;
	background-color: transparent;
	z-index: 999;
}
.slides .slick-prev {
	display: block !important;
	width: 50% !important;
	left: 0 !important;
	right: auto !important;
}
.slides .slick-next {
	display: block !important;
	width: 50% !important;
	right: 0 !important;
	left: auto !important;
}
.notouch .slides .slick-prev, .notouch .slides .slick-next {
	display: block !important;
}
.slides .slick-prev:hover {
    background: transparent url(../pics/icon_slides_prev.svg) no-repeat left center;
    @include background-size(24px, 24px);
}
.slides .slick-next.active, .slides .slick-next:hover {
    background: transparent url(../pics/icon_slides_next.svg) no-repeat right center;
    @include background-size(24px, 24px);
}
.slick-prev.slick-disabled, .slick-next.slick-disabled {
	background: none !important;	
}
*/
/* @end */




/* @group slider */
form {
	.slider .ui-slider-label {
		color: $bk-search-color !important;
	}
	
	.slider {
		margin-top: 1.8em;
		margin-bottom: 1em !important;
		height: 10px;
	    @include rounded(10px);
		border: none;
	}
	
	.slider .ui-widget-header {
		background-color: $bk-search-slider-bg-color;
		background-image: none;
	}
	
	.slider .ui-slider-pip {
		top: -12px;
	}
	
	.slider .ui-slider-pip .ui-slider-line {
		height: 8px;
		background: #bbb;
	}
	
	.slider .ui-slider-pip-inrange .ui-slider-line {
		background: #fff;
	}
	
	.slider .ui-slider-pip .ui-slider-label {
		top: -18px;
	}
	
	.slider .ui-slider-handle {
		width: 24px;
		height: 24px;
		top: -7px;
		border: none;
		@include rounded(24px);
		@include shadow(0,2px,6px,rgba(0,0,0,0.25));
	}
	
	.slider .ui-slider-range {
		@include rounded(12px);
	}
	
}
.bk-options form .slider .ui-slider-pip-inrange .ui-slider-line {
	background: $text-color;
}
.bk-options form .slider .ui-slider-label {
	color: $text-color !important;
}
.sliderlist {
	width: auto;
	li {
		width: 100% !important;
		padding-bottom: 2em !important;
		padding-right: 0 !important;
		label {
			span {
				padding-left: 0 !important;
				padding-bottom: 6px !important;
			}
		}
	}
	li:last-of-type {
		padding-bottom: 0 !important;
	}
}
.success {
	text-align: left;
	color: $bk-form-success-color;
	padding: 4px;
	padding-left: 0;
}
.error {
	text-align: left;
	color: $bk-form-error-color;
	padding: 4px;
	padding-left: 0;
}
.bk-search .error, .bk-first .error {
	color: $bk-form-error-color-light;
}
/* @end */



.bk-prop-selected {
	background-color: $bk-search-bg-color;
	color: $bk-search-color;
	padding-top: 3em;
	font-size: 1em;
	line-height: 1.3em;
	.columns {
		/*padding-bottom: 1em;
		padding-right: 0 !important;*/
		&:last-of-type {
			/*padding-right: 0 !important;*/
		}
	}
	.columns.large-2 {
		img {
			border: 1px solid rgba(255,255,255,0.5);
			margin-bottom: 1em;
		}
	}
	
}
.bk-options {
	background-color: #fff;
	color: $text-color;
	label {
		color: $text-color;
	}
}


.bk-prop-selected, .bk-options-summary {
	table {
		width: 100%;
		margin-top: 0.5em;
		margin-bottom: 1em;
		td {
			border-top: 1px solid #fff;
			padding: 3px;
			padding-left: 0;
			padding-right: 16px;
		}
		td:first-of-type {
			width: 50%;
		}
		td:last-of-type {
			font-weight: 700;
		}
	}
	/*ul {
		li {
			width: 50%;
			float: left;
			border-top: 1px solid #fff;
			padding: 6px !important;
			padding-left: 0 !important;
		}
	}*/
}
.bk-options .bk-options-summary {
	table {
		td {
			border-top: 1px solid $text-color;
		}
	}
	h2 {
		margin-bottom: 0.25em;
	}
	h2:last-child {
		margin-bottom: 0.75em;
	}
	h3 {
		margin-top: 0;
		padding-top: 4px;
	}
	ul {
		padding-bottom: 1em;
		li {
			width: 100%;
			float: none;
			padding-top: 0;
		}
	}
}
/* @end */


/* @group Notification */
#bk-search-note {
	position: fixed;
	z-index: 999;
	left: 0;
	bottom: $bk-search-note-bottom;
	background-color: $bk-search-note-bg-color;
	color: $bk-search-note-color;
	width: 100%;
	li {
		padding: 20px;
		text-align: center;
	}
	.fa-calculator {
		display: inline-block;
		font-size: 2rem !important;
		margin-right: 6px;
		color: $bk-search-note-icon-color;
	}
	li:last-of-type {
		font-size: 1.5rem;
		line-height: 1.3em;
	}
	p {
		padding-bottom: 0;
	}
}
/* @end */

.bk-options-group {
	.bk-summary-navbar {
		padding-top: 0 !important;
		ul {
			li {
				width: auto;
				float: left;
				border-top: none !important;
				padding-right: 0.5em !important;
				padding-bottom: 0.5em !important;
			}
			li:last-of-type {
				float: right;
				padding-right: 0 !important;
			}
		}
			
	}
}
.navtop {
	font-size: 2rem;
	font-weight: 300;
	/*float: right;
	margin-top: 10px;*/
	li {
		/*float: left;*/
		a {
			display: block;
			padding: 6px;
			padding-left: 12px;
			padding-right: 12px;
			color: $nav-color;
			&:hover {
				color: $nav-color-hover;
			}
		}
		&.active {
			a {
				color: $nav-color-active;
			}
		}
	}
}
.navlang {
	font-size: 2rem;
	font-weight: 300;
	position: absolute;
	/*top: 10px;
	right: 12px;*/
	li {
		float: left;
		a {
			display: block;
			padding: 6px;
			color: $nav-color;
			&:hover {
				color: $nav-color-hover;
			}
		}
		&.active {
			a {
				color: $nav-color-active;
			}
		}
	}
}




/* @group Results */
.bk-results, .bk-configurations {
	padding-top: 3.5em !important;
	> .row {
		padding-left: 0;
		padding-right: 0;
		> .columns {
			padding-right: 0;
			&:first-of-type {
				img {
					margin: auto;
				}
			}
		}
	}
	.bk-results-table, .bk-configurations-table {
		width: 100%;
		th, td {
			padding: 6px;
			padding-top: 8px;
			&.numeric {
				text-align: right;
			}
			.bk-btn-info-single {
				text-align: center;
				padding-left: 0;
				padding-right: 0;
				.bk-btn-info {
					background-color: $bk-results-active-bg-color;
				}
			}
			input[type=text] { /* inline editing */
				font-size: 1em;
				font-family: $font-regular;
				font-style: normal;
				font-weight: 400;
				border: 1px solid #ccc;
				color: $text-color;
				background-color: $bk-results-active-bg-color;
				
				width: calc(100% - 100px);
				min-width: 120px;
				padding: 6px;
				padding-left: 8px;
				margin-left: -9px;
				
				& ~ span {
					margin-left: 4px;
					color: $text-color;
					display: inline-block;
					padding: 2px;
					padding-top: 3px;
					padding-left: 6px;
					padding-right: 6px;
					text-transform: uppercase;
					font-size: 1em;
					line-height: 1.5em;
					font-weight: 700;
					outline: none;
					@include opacity(1);
					
					&:first-of-type { /* submit */
						border: 2px solid $bk-results-header-bg-color;
						background-color: #fff;
						padding-left: 10px;
						padding-right: 10px;
					}
					
					.no-touchevents &:hover {
						@include opacity(0.8);
						cursor: pointer;
					}
				}
			}
			
		}
		th {
			position: relative;
			background-color: $bk-results-header-bg-color;
			color: $bk-results-header-color;
			font-weight: 700;
			&.sortedby span {
				position: relative;
				display: inline-block;
				&:after {
					content: '';
					display: block;
					width: 0;
					height: 0;
					position: absolute;
					top: 8px;
					left: -16px;
					border-left: 6px solid transparent;
					border-right: 6px solid transparent;
					border-top: 6px solid #fff;
				}
			}
			&.sortedby.asc span {
				&:after {
					border-top: none;
					border-bottom: 6px solid #fff;
				}
			}
			&.info {
				width: 36px;
			}
		}
		td {
			position: relative;
			background-color: $bk-results-bg-color;
			border-bottom: 2px solid $bk-results-line-color;
			background-clip: padding-box;
		}
		.bk-result-row:hover td {
			background-color: $bk-results-active-bg-color;
		}
		.active td {
			background-color: $bk-results-active-bg-color;
			border-bottom: none;
			.bk-btn-info-single {
				.bk-btn-info {
					background-color: $bk-results-bg-color;
				}
			}
		}
		.bk-result-row td:first-of-type:after {
			position: absolute;
			display: block;
			z-index: 999;
			left: 8px;
			top: 9px;
			content: '';
			width: 0;
			height: 0;
			border-color: transparent;
			border-left-color: $link-color;
			border-style: solid;
			border-width: 10px 0 10px 10px;
				
		}
		.bk-result-row.active td:first-of-type:after {
			left: 2px;
			top: 14px;
			border-color: transparent;
			border-width: 10px 10px 0 10px;
			border-top-color: $link-color;
		}
		.bk-result-row td:hover {
			cursor: pointer;
		}
		.bk-result-detail {
			display: none;
			height: 1px;
			@include transition(opacity);
			@include opacity(0);
		}
		.bk-result-row.active + .bk-result-detail {
			display: table-row;
			@include opacity(1);
		}
		.bk-result-detail td {
			background-color: $bk-results-active-bg-color;
			padding-bottom: 2em;
			> div {
				/*display: table;*/
				width: 100%;
				> div {
					padding-top: 1em;
					padding-bottom: 1em;
					/*width: 50%;*/
					/*display: table-cell;*/
					vertical-align: middle;
					text-align: center;
				}
				> div:first-of-type {
					position: relative;
					h2 {
						font-size: 3.6rem;
						padding-left: 36px;
						padding-bottom: 12px;
					}
					P {
						padding-left: 36px;
						text-align: left;
					}
					.info {
						position: relative;
						text-align: left;
						small {
							position: relative;
							display: block;
							padding-left: 36px;
							span {
								position: absolute;
								top: -2px;
								left: 0;
								display: block;
								text-align: center;
								padding: 3px;
								width: 24px;
								margin-right: 4px;
								background-color: $bk-results-bg-color;
								@include rounded(12px);
							}
						}
					}
					.bk-result-images-caption {
						img {
							width: 50%; 
							float: right;
						}
					}
				}
				.bk-result-images {
					padding: 0;
				}
			}
		}
		.bk-result-empty td, .bk-configuration-empty td {
			text-align: center;
			padding: 100px;
		}
	}
	.bk-configurations-table {
		th:first-of-type, td:first-of-type {
			padding-left: 12px;
		}
		th:last-of-type, td:last-of-type {
			padding-right: 12px;
		}
		td {
			vertical-align: top;
			&:nth-child(2) {
				width: 36%;
			}
			&:last-of-type {
				padding-right: 0;
			}
			ul {
				float: right;
				min-width: 116px;
				li {
					float: left;
					padding-bottom: 0;
					padding-left: 8px;
					&:first-of-type {
						padding-left: 0;
					}
					a {
						font-size: 0.9rem;
						img {
							display: block;
						}
					}
				}
			}
		}
	}
	/* form filter bar configuration */
	.bk-configurations-filters {
		font-size: 0.9em;
		background-color: $bk-search-color;
		color: $text-color;
		margin-right: 40px !important;
		padding-bottom: 12px;
		> button { /* open/close button for mobiles */
			/*display: none;*/
			margin-bottom: 6px;
		}
		form {
			.row {
				position: relative;
				margin-left: 0 !important;
				margin-right: 42px !important;
				.columns {
					padding-right: 0 !important;
					label {
						display: block;
						text-transform: none;
						padding-bottom: 4px;
						font-weight: 400;
					}
					input {
						width: calc(100% - 10px);
						padding: 7px;
					}
					small {
						font-size: 0.7em;
					}
					button {
						margin: auto;
					}
					.custom-select {
						display: block;
						select {
							padding: 7px;
							padding-left: 8px;
							padding-right: 20px;
							&:focus {
								border: 1px solid $bk-search-field-color;
							}
						}
						&::after {
							right: 8px;
						}
					}
				}
			}
			button {
				position: absolute;
				right: -2px;
				bottom: 28px;
				margin-left: 4px;
				color: $text-color !important;
				display: inline-block;
				padding: 4px;
				padding-left: 6px;
				padding-right: 6px;
				text-transform: uppercase;
				font-size: 1em;
				line-height: 1.5em;
				font-weight: 700;
				outline: none;
				@include opacity(1);
				border: 2px solid $bk-results-header-bg-color;
				background-color: #fff;
				padding-left: 10px;
				padding-right: 10px;
			}
		}
	}
}
.bk-layout-images {
	padding: 16px;
	background-color: #fff;
	margin-bottom: 1em;
	a {
		display: block; 
		width: 48%; 
		float: left;
		@include opacity(1);
		@include transition(opacity);
		&:first-of-type {
			float: right;
		}
		&:hover {
			@include opacity(0.7);
		}
	}
}
/* @end */


/* @group Buttons */
.btn {
	border: 4px solid $link-color;
	color: $link-color !important;
	display: inline-block;
	padding: 4px;
	padding-top: 5px;
	padding-left: 24px;
	padding-right: 24px;
	text-transform: uppercase;
	font-size: 1em;
	line-height: 1.5em;
	font-weight: 700;
	outline: none;
	background-color: #fff;
	cursor: pointer;
	@include opacity(1);
}
.btn-inverted {
	background-color: $link-color !important;
	color: #fff !important;
	border: 4px solid #fff !important;
}
.no-touchevents .btn:hover { /* .notouch */
	cursor: pointer;
	@include opacity(0.8);
}
/* when info btn set outside label use wrapper 
<div class="group bk-btn-info-single"> */
.bk-btn-info-single { 
	position: relative;
	.bk-btn-info {
		right: -32px;
		top: 7px;
		/*> span {
			> span {
				left: -224px;
			}
			> span:after {
				left: 100%;
				margin-left: -32px;
			}
		}*/
	}
}
.bk-btn-info {
	position: absolute;
	right: -28px;
	top: 0;
	display: inline-block;
	width: 24px;
	height: 24px;
	text-align: center;
	color: $bk-search-field-color;
	text-transform: none;
	background-color: #fff;
	font-family: $font-regular;
	font-size: 1.8rem;
	line-height: 1.4em;
	font-style: normal;
	font-weight: 700;
	@include rounded(24px);
	> span {
		position: absolute;
		display: block;
		bottom: 0;
		left: 50%;
		color: $bk-search-field-color;
		display: none;
		> span {
			display: block;
			position: absolute;
			bottom: 42px;
			left: -120px;
			z-index: 999;
			padding: 12px;
			padding-left: 16px;
			width: 240px;
			font-size: 1.4rem;
			line-height: 1.3em;
			text-align: left;
			white-space: normal;
			background-color: $bk-search-field-bg-color;
			@include shadow(0,2px,6px,rgba(0,0,0,0.25));
		}
		> span:after {
			  position: absolute;
			  display: block;
			  left: 50%;
			  margin-left: -16px;
			  bottom: -16px;
			  content: '';
			  width: 0;
			  height: 0;
			  border-color: transparent;
			  border-top-color: $bk-search-field-bg-color;
			  border-style: solid;
			  border-width: 16px 16px 0 16px;
		}
	}
}
.bk-options .bk-btn-info {
	color: $bk-search-field-color;
	background-color: $bk-search-field-bg-color;
}
.bk-result-row .bk-btn-info-single { /* info button result list */
	position: relative;
	.bk-btn-info {
		position: relative;
		right: auto;
		top: auto;
		margin-left: 0;
	}
}
.bk-btn-info:hover {
	cursor: pointer;
	> span {
		display: block;
	}
}
.disabled .bk-btn-info:hover {
	cursor: default;
	> span {
		display: none;
	}
}
.bk-submit {
	text-align: center;
	padding-top: 1em;

	button {
		&.btn {
			font-size: 2.2rem;
			line-height: 1.3em;
			font-family: "Roboto Condensed",Helvetica,Geneva,sans-serif;
			font-style: normal;
			font-weight: 400;

			&:hover {
				cursor: pointer;
				opacity: .8;
			}
		}
	}
}
/* @end */


/* @group Select */
.custom-select {
	display: inline-block;
	border: 1px solid #bbb;
	@include rounded(8px);
	background-color: $bk-search-field-bg-color;
}
.custom-select select {
	font-size: 0.9em;
	background: none !important;
	padding-top: 0.3em;
	padding-bottom: 0.3em;
	border: none;
	outline: none;
	@include rounded(8px);
}
.custom-select:after {
	width: 0;
	height: 0;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 7px solid $bk-search-field-color;
	margin-top: -3px;
}
.custom-select:hover {
	border-color: #888;
}
.custom-select select:focus {
	outline: none;
	border: none;
	box-shadow: 0 0 1px 3px rgba(180,222,250, 1);
	color: #222;
}
/* @end */


/* @group Radios */
.radio-check {
	display: block;
  	position: absolute;
  	border: 2px solid $bk-search-color;
  	border-radius: 100%;
  	height: 24px;
  	width: 24px;
  	top: 0;
  	left: 0;
	z-index: 998;
	/*transition: border .25s linear;
	-webkit-transition: border .25s linear;*/
}
label:hover .radio-check {
  border: 2px solid $bk-search-color;
}
.radio-check::before {
	display: block;
  	position: absolute;
	content: '';
	border-radius: 100%;
  	height: 16px;
  	width: 16px;
  	top: 0;
	left: 0;
	margin: auto;
	/*transition: background 0.25s linear;
	-webkit-transition: background 0.25s linear;*/
}
input[type=radio] {
	  visibility: hidden;
	  position: absolute;
}
input[type=radio]:checked ~ span .radio-check {
	border-color: $bk-search-color;
}
input[type=radio]:checked ~ span .radio-check::before {
	background: $bk-search-color;
	top: 2px;
	left: 2px;
}

.bk-options .radio-check {
	border: 2px solid $bk-radio-line-color;
}
.bk-options label:hover .radio-check {
	border: 2px solid $bk-radio-line-hover-color;
}
.bk-options input[type=radio]:checked ~ span .radio-check {
	border-color: $bk-radio-line-checked-color;
}
.bk-options input[type=radio]:checked ~ span .radio-check::before {
	background: $bk-radio-inner-checked-color;
	top: 2px;
	left: 2px;
}
/*input[type=radio]:checked ~ label { 
  color: #0DFF92;
}*/
/* @end */


.costs-additional {
	display: inline-block;
	font-size: 1.3rem !important;
	line-height: 1.3em !important;
	font-weight: 400 !important;
	color: $bk-option-note-costs-color;
}




/* @group Small Screens */
@include responsive(small-screens) {
	@include toggle-device-tag(smartphone);

	h1 {
		font-size: 4rem;
		line-height: 1em;
	}
	header {
		#logo {
			width: 75px;
		}
		.hamburger {
			z-index: 2;
			top: 2px;
			right: 6px;
			display: block;
		}
		.navtop {
			position: fixed; 
			z-index: 1;
			top: 46px;
			left: 0;
			width: 100%;
			background-color: $header-bg-color;
			padding-top: 6px;
			padding-bottom: 12px;
			
			display: none;
			float: none;
			li {
				float: none;
			}
		}
		.navlang {
			top: 10px;
			right: auto;
			left: 16px;
		}
	}
	main { //#content
		padding-top: $content-padding-top-small;
		padding-bottom: $content-padding-bottom-small;
		> section {
			> .row, > form > .row {
				padding-left: $content-padding-left-small;
				padding-right: $content-padding-right-small;
			}
		}
	}
	.bk-intro {
		padding-top: 0.5em;
		padding-bottom: 2em;
	}
	.bk-intro {
		> .row {
			> .columns {
				padding-right: $content-padding-right-small !important; 
				&:first-of-type {
					h1 {
						text-align: left;
					}
				}
			}
		}
	}
	#bk-breadcrumb {
		> div {
			padding-left: $content-padding-left-small * 2;
			padding-right: $content-padding-right-small * 2;
		}
	}
	
	.bk-search, .bk-options, .bk-first {
		form {
			ul {
				li {
					width: auto;
					float: none;
					ul {
						li {
							float: left;
						}
					}
				}
			}
			/* forms on single pages (login...) */
			ol {
				li.small-12 {
					padding-right: 0 !important;
				}
			}
		}
		/*.bk-search-group-option-layout {
			ul {
				li {
					float: left;
					padding-bottom: 1em;
				}
			}
		}*/
		.bk-search-group-basic {
			.columns {
				padding-left: 40px;
				padding-right: 40px;
				> div {
					float: none;
					margin: auto;
					width: auto;
				}
			}
			.columns:first-of-type {
				> div {
					float: none;
				}
				&:after {
					display: none;
				}
			}
		}
		.bk-options-types {
			ul {
				li {
					width: auto !important;
					float: none !important;
				}
			}
		}
		&.bk-search {
			h2 {
				padding-bottom: 1em;
			}
			.bk-search-group-option-ratio {
				h2 {
					padding-bottom: 0.25em;
				}
			}
		}
	}
	
	.bk-options-icons, .bk-options-thumbs {
		ul {
			margin-right: -12px;
			li {
				width: 50% !important;
				float: left !important;
			}
		}
	}
	.bk-options-radios {
		.columns:first-of-type {
			padding-right: $content-padding-right-small * 2;
		}
	}
	.bk-prop-selected {
		.columns {
			padding-bottom: 1em;
			padding-right: 0 !important;
			&:last-of-type {
				padding-right: 0 !important;
			}
		}
	}
	.bk-results {
		> .row {
			> .columns {
				> p {
					padding-left: $content-padding-left-small;
				}
			}
		}
		.bk-results-table {
			.bk-result-detail td {
				> div {
					display: block;
					> div {
						width: auto;
						display: block;
					}
				}
			}
		}
	}
	//.bk-btn-info-single { 
		.bk-btn-info-mobile-left, .bk-btn-info-single .bk-btn-info { // was .bk-btn-info
			> span {
				> span {
					left: -224px;
				}
				> span:after {
					left: 100%;
					margin-left: -32px;
				}
			}
		}
	//}
	/* form filter bar configuration */
	.bk-configurations-filters {
		form {
			display: none;
		}
		> button { /* open/close button for mobiles */
			display: inline-block;
		}
	}
}


@media only screen and (max-width: $width-medium),
(min-device-width: 120px) and (max-device-width: $width-medium)  {
	
	table.bk-results-table, table.bk-results-table thead, table.bk-results-table tbody, table.bk-results-table th, table.bk-results-table td, table.bk-results-table tr { 
		display: block; 
	}
	.bk-results-table thead tr { 
		display: none;
	}
	.bk-results-table td {
		border-bottom: none !important;
	}
	.bk-results-table .bk-result-row td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #ccc !important; 
		position: relative;
		padding-left: 45% !important; 
		padding-top: 3px !important;
		padding-bottom: 3px !important;
		&:first-of-type {
			border-bottom: none !important;
		}
	}
	.bk-results-table .bk-result-row td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 3px;
		left: 0;
		width: 20%; 
		padding-left: 10px;
		padding-right: 10px; 
		white-space: nowrap;
	}
	.bk-results-table .bk-result-row td:not(.nice-border):nth-of-type(2):before { content: "Trakt"; }
	.bk-results-table .bk-result-row td:nth-of-type(3):before { content: "Etage"; }
	.bk-results-table .bk-result-row td:nth-of-type(4):before { content: "Fläche"; }
	.bk-results-table .bk-result-row td:nth-of-type(5):before { content: "m2/Monat"; }
	.bk-results-table .bk-result-row td:nth-of-type(6):before { content: "Jahresmietzins"; }
	.bk-results-table .bk-result-row td:nth-of-type(7):before { content: ""; }
	
}
/* @end */



/* @group Medium Screens */
@include responsive(medium-screens) {
	@include toggle-device-tag(tablet);
	
	h1 {
		font-size: 3.6rem;
		line-height: 1em;
	}
	header {
		#logo {
			width: 100px;
		}
		.hamburger {
			display: none;
		}
		.navtop {
			display: inline-block !important;
			position: relative; 
			top: auto;
			left: auto;
			width: auto;
			background-color: transparent;
			padding-top: 0;
			padding-bottom: 0;
			float: none;
			margin-top: 10px;
			text-transform: uppercase;
			li {
				float: left;
			}
		}
		.navlang {
			top: 10px;
			right: 12px;
			left: auto;
		}
	}
	main { //#content
		padding-top: $content-padding-top-large;
		padding-bottom: $content-padding-bottom-large;
		> section {
			> .row, > form > .row {
				padding-left: $content-padding-left-large;
				padding-right: $content-padding-right-large;
			}
		}
	}
	.bk-intro {
		padding-top: 2em;
		padding-bottom: 2em;
	}
	.bk-intro {
		> .row {
			> .columns {
				padding-right: $content-padding-right-large !important; 
				&:first-of-type {
					h1 {
						text-align: right;
					}
				}
			}
		}
	}
	#bk-breadcrumb {
		> div {
			padding-left: $content-padding-left-large * 2;
			padding-right: $content-padding-right-large * 2;
		}
	}
	.bk-search, .bk-options, .bk-first {
		form {
			ul {
				li {
					width: 50%;
					float: left;
					ul {
						li {
							float: none;
						}
					}
				}
			}
			/* forms on single pages (login...) */
			ol {
				li.small-12 {
					padding-right: 0.9375em !important;
					&:last-of-type {
						padding-right: 0 !important;
					}
				}
			}
		}
			
			.bk-search-group-option-layout {
				ul {
					li {
						float: left;
						padding-bottom: 0;
					}
				}
			}
			.bk-search-group-basic {
				.columns {
					padding-left: 60px;
					padding-right: 60px;
					> div {
						float: left;
						width: 260px;
					}
				}
				.columns:first-of-type {
					> div {
						float: right;
					}
					&:after {
						display: block;
					}
				}
			}
			.bk-options-types {
				ul {
					li {
						width: 33% !important;
						float: left !important;
					}
				}
			}
		&.bk-search {
			h2 {
				padding-bottom: 0;
			}
			.bk-search-group-option-ratio {
				h2 {
					padding-bottom: 0;
				}
			}
		}
	}
	.bk-options-icons {
		ul {
			margin-right: -1 * $content-padding-right-large;
			li {
				width: 50% !important;
			}
		}
	}
	.bk-options-radios {
		.columns.bk-options-radios {
			padding-right: $content-padding-right-large * 2;
		}
		.columns {
			ul {
				li {
					width: 100% !important; /* options whole width */
				}
			}
		}
		
	}
	.bk-prop-selected, .bk-options-summary {
		.columns {
			padding-bottom: 0;
			padding-right: 2.5em !important;
			&:last-of-type {
				padding-right: 0 !important;
			}
		}
	}
	.bk-results {
		> .row {
			> .columns {
				> p {
					padding-left: 0;
				}
			}
		}
		.bk-results-table {
			.bk-result-detail td {
				> div {
					display: table;
					> div {
						width: 50%;
						display: table-cell;
					}
				}
			}
		}
	}
	//.bk-btn-info-single { 
		.bk-btn-info-mobile-left, .bk-btn-info-single .bk-btn-info { // was .bk-btn-info
			> span {
				> span {
					left: -120px;
				}
				> span:after {
					left: 50%;
					margin-left: -16px;
				}
			}
		}
	//}
	/* form filter bar configuration */
	.bk-configurations-filters {
		form {
			display: block !important;
		}
		> button { /* open/close button for mobiles */
			display: none;
		}
	}
}

@include responsive(medium-screens-portrait) {
	@include toggle-device-tag(tablet-portrait);
	
	h1 {
		font-size: 5rem;
		line-height: 1em;
	}
	main { //#content
		padding-top: $content-padding-top-small;
		padding-bottom: $content-padding-bottom-small;
		> section {
			> .row, > form > .row {
				padding-left: $content-padding-left-small;
				padding-right: $content-padding-right-small;
			}
		}
	}
	/*.bk-intro {
		p {
			padding-left: 25%;
		}
	}*/
	.bk-search, .bk-options, .bk-first {
		.bk-search-group-basic {
			.columns {
				padding-left: 40px;
				padding-right: 40px;
			}
		}
	}
	
}
/* @end */

/* @group Wide Screens */
@include responsive(wide-screens) {
	@include toggle-device-tag(desktop);
	
	#bk-breadcrumb {
		> div {
			padding-left: $content-padding-left-large * 3;
			padding-right: $content-padding-right-large * 3;
		}
	}
	.bk-search, .bk-options, .bk-first {
		form {
			ul {
				li {
					ul {
						li {
							float: left;
						}
					}
				}
			}
		}
			.bk-search-group-basic {
				.columns {
					padding-left: 90px;
					padding-right: 90px;
				}
			}
		
		
	}
	.bk-options-icons {
		ul {
			margin-right: -1 * $content-padding-right-large;
			li {
				width: 33% !important;
			}
		}
	}
}
/* @end */

/* @group Extra Wide Screens */
@include responsive(extra-wide-screens) {
	@include toggle-device-tag(desktop);
}
/* @end */



